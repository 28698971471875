<template>
  <input type="checkbox" name="" id="sidebar-toggle">

  <!-- Sidebar -->
  <Sidebar />
  <!-- End of Sidebar -->

  <!-- <img src="/img/2.jpg" style="position:fixed;top:0;left:0;z-index:90;width:300px;" /> -->

  <!-- Main Content -->
  <div class="main-content">

    <!-- Topbar -->
    <Navbar />
    <!-- End of Topbar -->

    <!-- Begin Page Content -->
    <main>

      <!-- lokasi kontenx disini -->
      <router-view />

    </main>
    <!-- /.container-fluid -->

  </div>
  <!-- End of Main Content -->

  <!-- Footer -->
  <footer class="pt-4 py-4">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Copyright &copy;2022 <a href="https://trisoft.my.id" target="_blank" style="text-decoration: none;">TriSoft</a> <small>Trilingga Solusi Informatika</small></span>
      </div>
    </div>
  </footer>
  <!-- End of Footer -->

  <label for="sidebar-toggle" class="body-label"></label>

</template>

<script>
import axios from "axios";
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/Navbar.vue";
export default {
  name: "Dashboard",
  components: {
    Sidebar,
    Navbar,
  },
  methods: {
    logOut() {
      localStorage.removeItem("token_app");
      this.$store.dispatch("acPengguna", {}); //membersihkan data pengguna di state
      this.$router.push("/login");
    },
  },
  created() {
    // swal(localStorage.getItem("token_app"));
    //memanggil ulang data pengguna untuk keperluan dashboard - > dipanggil disini agar seperti real time bsa buat inbox
    axios
      .get("api/pengguna", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token_app"),
        },
      })
      .then((response) => {
        this.$store.dispatch("acPengguna", response.data.datae);
      })
      .catch((err) => {
        swal("User lain sedang login dengan akun ini!");
        localStorage.removeItem("token_app");
        this.$store.dispatch("acPengguna", {}); //membersihkan data pengguna di state
        this.$router.push("/login");

        return;
        console.log(err);
      });
  },
  beforeCreate() {
    //panggil javascript library lainnya
    // var scripts = [
    //   "/js/sb-admin-2.min.js", //tambahkan
    // ];
    // scripts.forEach((script) => {
    //   let tag = document.createElement("script");
    //   tag.setAttribute("src", script);
    //   document.head.appendChild(tag);
    // });
  },
};
</script>


