<template>
  <div class="container">
    <div class="row content content-login">
      <div class="col-md-6">
        <img src="/assets/img.png" alt="login" class="img-fluid">
      </div>
      <div class="col-md-6">
        <h3 class="signin-text mb-0">ASi UKM</h3>
        <small class="mb-5">Akuntansi Simpel untuk UKM</small> <br><br>
        <form @submit.prevent="prosesLogin" class="justify-content-center">
          <div class="form-group">
            <label for="usernama">Nomor Ponsel</label>
            <input type="tel" name="usernama" v-model="usernama" class="form-control form-login mb-3">
          </div>
          <div class="form-group">
            <label for="pass">Kata Sandi</label>
            <input type="password" name="pass" v-model="pass" class="form-control form-login mb-3">
          </div>
          <!-- <div class="form-group">
                        <input type="checkbox" name="ch" id="ch" class="form-check-input"> 
                        <label for="ch" class="form-check-label"> &nbsp;Ingat Saya</label>
                    </div>  -->
          <button class="btn btn-primary btn-block mt-3">Masuk</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      usernama: "",
      pass: "",
    };
  },
  methods: {
    prosesLogin() {
      this.$router.push("/");

      //ambil data v-model yg ada di template
      const datax = {
        usernama: this.usernama,
        pass: this.pass,
      };
      axios
        .put("admin/login", datax)
        .then((res) => {
          //    console.log(res.data);
          if (res.data.status == "sukses") {
            localStorage.setItem("token_app", res.data.data.token_app);
            localStorage.setItem("levelx", res.data.data.levelx);
            this.$store.dispatch("acPengguna", res.data.data); //mengirim ke state agar data bisa ditampilkan di navbar atau sidebar
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token_app"); //update bearer
            swal("Berhasil", res.data.pesan, "success");
            this.$router.push("/");
          } else {
            swal("Gagal", res.data.pesan,{ icon: "error"} );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeCreate: function () {
    document.body.className = "login";
  },
};
</script>

<style>
body.login {
  background-color: #212529!important;
  /* background-color: #1a202e!important; */
}
.content-login {
  margin: 20% 1% 20% 1%;
  background-color: #fff;
  padding: 3em 1em 3em 1em;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.3);
}

.signin-text {
  font-style: normal;
  font-weight: 600 !important;
}

.form-login {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  border-color: #00ac96 !important;
  border-style: solid !important;
  border-width: 0 0 1px 0 !important;
  padding: 0px !important;
  color: #495057;
  height: auto;
  border-radius: 0;
  background-color: #fff;
  background-clip: padding-box;
}

.form-login:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  border-width: 0 0 2px 0 !important;
  box-shadow: none;
}

.btn-class {
  border-color: #00ac96;
  color: #00ac96;
}

.btn-class:hover {
  background-color: #00ac96;
  color: #fff;
}
</style>