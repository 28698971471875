<template>
  <ol class="breadcrumb rounded-0 bg-white">
    <li class="breadcrumb-item">
      <b>Keuangan</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Pos Akun</li>
  </ol>
  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="d-none d-sm-none d-md-block">
            <div class="card-header py-3 d-flex justify-content-between">
              <div><strong>Pos Akun Keuangan</strong></div>
              <div>
                <div class="input-group">
                  <input type="text" class="form-control form-control-sm" placeholder="Pencarian ..."
                    aria-label="Pencarian ..." aria-describedby="pencarian" v-model="cari" @keyup="pencarian" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-primary" @click="addModal">
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      Tambah
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-sm-block d-md-none">
            <div class="card-header py-3 d-flex justify-content-between">
              <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="Pencarian ..."
                    aria-label="Pencarian ..." aria-describedby="pencarian" v-model="cari" @keyup="pencarian" />
                    <div class="input-group-append">
                    <button type="button" class="btn btn-sm btn-primary" @click="addModal">
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    
                    </button>
                  </div>
                  </div>

            </div>
          </div>

          <div class="card-body">
            <button class="btn btn-block btn-outline-danger" @click="unduh" v-if="getPengguna.unduhnoperk=='0'">Unduh Template</button>
            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>
            <div class="table-responsive" v-show="!loading">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="d-none d-sm-none d-md-block">#</th>
                    <th>No Akun</th>
                    <th>Nama Akun</th>
                    <th>Golongan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, index) in datafiltere" :key="index">
                    <th scope="row" class="d-none d-sm-none d-md-block">{{ index + 1 }}</th>
                    <td class="small"  @click="getUnitDetail(x.id, index)">{{ x.noperk }}</td>
                    <td  @click="getUnitDetail(x.id, index)">{{ x.namaperk }}</td>
                    <td class="small">
                      <span v-if="x.kelompok == '1'">Aset</span>
                      <span v-if="x.kelompok == '2'">Kewajiban</span>
                      <span v-if="x.kelompok == '3'">Modal</span>
                      <span v-if="x.kelompok == '4'">Pendapatan</span>
                      <span v-if="x.kelompok == '5'">HPP</span>
                      <span v-if="x.kelompok == '6'">Biaya</span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button type="button" class="btn btn-danger btn-sm" @click="deleteUnit(x.id, index)"
                          v-if="x.penting == 'T'">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p align="center" v-show="kosong">
              Belum ada data. Klik tombol tambah untuk menambahkan
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal-->
  <div id="modalTambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    class="modal fade text-left">
    <div role="document" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <strong id="exampleModalLabel" class="modal-title">Pos Akun</strong>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group col-sm-6">
              <label>Golongan Akun</label>
              <select class="form-control form-control-sm" v-model="golakun">
                <option v-for="(x, index) in list_golakun" :key="index" :value="x.no">
                  {{ x.gol }}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label>No Akun</label>
              <input type="text" placeholder="No Akun" class="form-control form-control-sm" v-model="noperk"
                maxlength="3" />
            </div>
            <div class="form-group col-sm-12">
              <label>Nama Akun</label>
              <input type="text" placeholder="Nama Akun" class="form-control form-control-sm" v-model="namaperk" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">
            Batal
          </button>
          <button type="button" class="btn btn-primary" @click.prevent="simpan">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { $array } from "alga-js";

export default {
  name: "Kelas",
  data() {
    return {
      columns: {},
      datae: {},
      datafiltere: {},
      noperk: "",
      namaperk: "",
      id: "",
      kosong: false,
      loading: false,
      list_golakun: [
        { no: "1", gol: "Aset" },
        { no: "2", gol: "Kewajiban" },
        { no: "3", gol: "Modal" },
        { no: "4", gol: "Pendapatan" },
        { no: "5", gol: "HPP" },
        { no: "6", gol: "Biaya" },
      ],
      golakun: 6,
      index: "", //untuk digunakan update dan hapus secera real time,
      cari: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getUnit();
  },
  methods: {
    getUnit() {
      this.loading = true;
      axios
        .get("api/noperk")
        .then((res) => {
          this.datae = res.data;
          this.datafiltere = $array.search(this.cari)(this.datae);

          this.columns = [
            {
              label: "No Akun",
              field: "noperk",
            },
            {
              label: "Nama Akun",
              field: "namaperk",
            },
            {
              label: "Golongan",
              field: "kelompok",
            },
          ];

          if (res.data.length > 0) {
            this.kosong = false;
          } else {
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnitDetail(x, index) {
      axios
        .get("api/noperk/" + x)
        .then((res) => {
          // console.log(res.data);
          this.golakun = res.data.kelompok;
          this.noperk = res.data.noperkx;
          this.namaperk = res.data.namaperk;
          this.id = res.data.id;
          this.index = index;
          $("#modalTambah").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addModal() {
      this.noperk = "";
      this.namaperk = "";
      this.golakun = "6";
      this.id = "";
      $("#modalTambah").modal("show");
    },
    addUnit() {
      const datax = {
        noperk: this.golakun + this.noperk,
        namaperk: this.namaperk,
      };
      axios
        .post("api/noperk", datax)
        .then((res) => {
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.getUnit();
          $("#modalTambah").modal("hide");
          swal("Berhasil", res.data.pesan, "success");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editUnit() {
      const datax = {
        id: this.id,
        noperk: this.golakun + this.noperk,
        namaperk: this.namaperk,
      };
      axios
        .put("api/noperk", datax)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.datafiltere[this.index].noperk = this.golakun + this.noperk; //untuk mengedit array secara langsung
          this.datafiltere[this.index].namaperk = this.namaperk; //untuk mengedit array secara langsung
          $("#modalTambah").modal("hide");
          swal("Berhasil", res.data.pesan, "success");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUnit(x, index) {
      swal({
        title: "Hapus Kelas ini?",
        text: "Sekali terhapus data tidak akan bisa dikembalikan!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete("api/noperk/" + x)
            .then((res) => {
              if (res.data.status == "gagal") {
                swal("Gagal", res.data.pesan, "error");
                return;
              }
              this.datafiltere.splice(index, 1); //untuk menghapus array secara langsung

              // swal("Berhasil", "data telah dihapus!", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //		 swal("Your imaginary file is safe!");
        }
      });
    },
    simpan() {
      if (!this.noperk || !this.namaperk) {
        swal("Perhatian", "Lengkapi isian!", "warning");
        return;
      }
      if (this.id) {
        this.editUnit();
      } else {
        this.addUnit();
      }
    },
    pencarian() {
      this.datafiltere = $array.search(this.cari)(this.datae);
    },
    async unduh(){
      const datax={
        'idx':'0'
      }
      let res = await axios.put('api/noperk/unduh', datax);
      this.getUnit();
      swal("Unduh template No Akun Perkiraan Berhasil!");

      
    }
  },
  computed: {
    ...mapGetters(["getPengguna"]),
  },
};
</script>

<style>

</style>
