<template>
  <div class="page-header">
    <div>
      <h1>ASi UKM</h1>
      <small>Akuntasi Simple UKM <i class="fas fa-user"></i> <!-- uses solid style --></small>
    </div>

    <!-- <div class="header-actions">
      <button>
        <span class="las la-file-export"></span>
        Export
      </button>
      <button>
        <span class="las la-tools"></span>
        Settings
      </button>
    </div> -->
  </div>

  <!-- <div class="cards">
    <div class="card-single">
      <div class="card-flex">
        <div class="card-info">
          <div class="card-head">
            <span>Purchases</span>
            <small>Number of purchases</small>
          </div>

          <h2>17,663</h2>

          <small>2% less purchase</small>
        </div>
        <div class="card-chart danger">
          <span class="las la-chart-line"></span>
        </div>
      </div>
    </div>

    <div class="card-single">
      <div class="card-flex">
        <div class="card-info">
          <div class="card-head">
            <span>Refunds</span>
            <small>Value of refunded orders</small>
          </div>

          <h2>$4,523.11</h2>

          <small>10% less refund</small>
        </div>
        <div class="card-chart success">
          <span class="las la-chart-line"></span>
        </div>
      </div>
    </div>

    <div class="card-single">
      <div class="card-flex">
        <div class="card-info">
          <div class="card-head">
            <span>Unique Visitors</span>
            <small>Number of visitors</small>
          </div>

          <h2>46,085</h2>

          <small>2% less visitors</small>
        </div>
        <div class="card-chart yellow">
          <span class="las la-chart-line"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="jobs-grid">
    <div class="analytics-card">
      <div class="analytics-head">
        <h3>Actions needed</h3>

        <span class="las la-ellipsis-h"></span>
      </div>

      <div class="analytics-chart">
        <div class="chart-circle">
          <h1>74%</h1>
        </div>

        <div class="analytics-note">
          <small>Note: Current sprint requires stakeholders meeting to reach conclusion</small>
        </div>
      </div>

      <div class="analytics-btn">
        <button>Generate report</button>
      </div>
    </div>

    <div class="jobs">
      <h2>Jobs <a href=""><small>See all jobs <span class="las la-arrow-right"></span></small></a></h2>

      <div class="table-responsive">
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <div>
                  <span class="indicator"></span>
                </div>
              </td>
              <td>
                <div>
                  Customer experience designer
                </div>
              </td>
              <td>
                <div>
                  Design
                </div>
              </td>
              <td>
                <div>
                  Copenhagen Dk.
                </div>
              </td>
              <td>
                <div>
                  Posted 6 days ago
                </div>
              </td>
              <td>
                <div>
                  <button>8 applicants</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div><span class="indicator even"></span></div>
              </td>
              <td>
                <div>Software developer</div>
              </td>
              <td>
                <div>Developer</div>
              </td>
              <td>
                <div>Copenhagen Dk.</div>
              </td>
              <td>
                <div>Posted 6 days ago</div>
              </td>
              <td>
                <div>
                  <button>3 applicants</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <span class="indicator"></span>
                </div>
              </td>
              <td>
                <div>
                  Customer experience designer
                </div>
              </td>
              <td>
                <div>
                  Design
                </div>
              </td>
              <td>
                <div>
                  Copenhagen Dk.
                </div>
              </td>
              <td>
                <div>
                  Posted 6 days ago
                </div>
              </td>
              <td>
                <div>
                  <button>8 applicants</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div><span class="indicator even"></span></div>
              </td>
              <td>
                <div>Software developer</div>
              </td>
              <td>
                <div>Developer</div>
              </td>
              <td>
                <div>Copenhagen Dk.</div>
              </td>
              <td>
                <div>Posted 6 days ago</div>
              </td>
              <td>
                <div>
                  <button>3 applicants</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <span class="indicator"></span>
                </div>
              </td>
              <td>
                <div>
                  Customer experience designer
                </div>
              </td>
              <td>
                <div>
                  Design
                </div>
              </td>
              <td>
                <div>
                  Copenhagen Dk.
                </div>
              </td>
              <td>
                <div>
                  Posted 6 days ago
                </div>
              </td>
              <td>
                <div>
                  <button>8 applicants</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";
export default {
  name: "Beranda",
  data() {
    return {
      jumbaru: 0,
      jumproses: 0,
      nofaktur: "",
      selisih: 0,
      status: "",
      pesan: "",
      status_proses: "",
      loading: false,
      kosong: false,
      timer: "",
    };
  },
  mounted() {
    // this.getBeranda();
    // this.timer = setInterval(this.getBeranda, 60000);
  },
  methods: {
    getBeranda() {
      this.loading = true;
      axios
        .get("api/antrian/harini")
        .then((res) => {
          this.jumbaru = res.data.jumbaru;
          this.jumproses = res.data.jumproses;
          if (res.data.status == "ok") {
            this.nofaktur = res.data.nofaktur;
            this.selisih = res.data.selisih;
            this.status = res.data.status;
            this.pesan = res.data.pesan;
            this.status_proses = res.data.status_proses;
            this.loading = false;
            this.kosong = false;
          } else {
            this.kosong = true;
            this.loading = false;
          }
        })
        .catch((res) => {});
    },
  },
};
</script>

<style>
</style>