<template>
  <div class="sidebarx">
    <div class="sidebar-brand">
      <div class="brand-flex">
        <!-- <span><img src="/img/logo.png" width="30" alt=""> Biznizo</span> -->
        <img src="/img/logo.png" width="30" alt="">

        <div class="brand-icons">
          <span class="icon-menu las la-user-circle" data-bs-toggle="dropdown" aria-expanded="false" id="iconMenuProfil"></span>
          <ul class="dropdown-menu list-icon-menu" aria-labelledby="iconMenuProfil">
            <li><a class="dropdown-item" href="#">Keamanan</a></li>
            <li><a class="dropdown-item" href="" @click="logOut">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="sidebar-main">
      <div class="sidebar-user">
        <div class="img text-center">
          <img src="/img/logo.png" alt="">
         <!-- <img :src="mainurl_file+'/upload/profil/'+getPengguna.iduser+'.jpg'" alt=""> -->
        </div>
        <div class="mt-2">
          <h3>{{getPengguna.namalengkap}}</h3>
          <small v-if="getPengguna.levelx=='1'">Staff Akuntansi<br>{{getPengguna.sekolah}}</small>
          <small v-if="getPengguna.levelx=='0'">Pemilik Usaha<br>{{getPengguna.yayasan}}</small><br>
        </div>
      </div>

      <div class="sidebar-menu">
        <div class="menu-head">
          <span>Dashboard</span>
        </div>
        <ul class="menu-app">
          <li :class="[{'active':$route.name==='Beranda'}]">
            <router-link to="/" href="#">
              <span class="las la-tachometer-alt"></span>
              Ringkasan
            </router-link>
          </li>
        </ul>

        <div class="menu-head">
          <span>Operasional</span>
        </div>
        <ul class="menu-app">
          <li class="dropdown-btn">
            <a href="#mnuData" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-server"></span>
              Basis Data
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="mnuData" class="collapse">
            <li :class="[{'active':$route.name==='Kegiatan'}]">
              <router-link to="/kegiatan">Kegiatan</router-link>
            </li>   
            <li :class="[{'active':$route.name==='Mitra'}]">
              <router-link to="/mitra">Mitra Usaha</router-link>
            </li>           
          </ul>
 
          <li class="dropdown-btn">
            <a href="#mnuKeuangan" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-book"></span>
              Operasional
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="mnuKeuangan" class="collapse">
            <li :class="[{'active':$route.name==='Noperk'}]" v-if="getPengguna.levelx=='0'">
              <router-link to="/noperk">Pos Akun</router-link>
            </li>
            <li :class="[{'active':$route.name==='Bank'}]" v-if="getPengguna.levelx=='0'">
              <router-link to="/bank">Bank</router-link>
            </li>
          </ul>

          <li class="dropdown-btn">
            <a href="#mnuTrx" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-pen-alt"></span>
              Transaksi
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="mnuTrx" class="collapse">          
            <li :class="[{'active':$route.name==='TransaksiKas'||$route.name==='AddKas'}]">
              <router-link to="/trkas">Transaksi Kas</router-link>
            </li>
            <li :class="[{'active':$route.name==='TransaksiBank'||$route.name==='AddBank'}]">
              <router-link to="/trbank">Transaksi Bank</router-link>
            </li>
            <li>
              <router-link to="">Jurnal Memorial</router-link>
            </li>
          </ul>
          
          <li class="dropdown-btn">
            <a href="#mnuProduksi" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-industry"></span>
              Produksi
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="mnuProduksi" class="collapse">          
            <li :class="[{'active':$route.name==='TransaksiProduksi' && $route.params.tipe==='0'}]">
              <router-link to="/trproduksi/0">Bahan Baku</router-link>
            </li>
            <li :class="[{'active':$route.name==='TransaksiProduksi' && $route.params.tipe==='1'}]">
              <router-link to="/trproduksi/1">Tenaga Kerja</router-link>
            </li>
            <li :class="[{'active':$route.name==='TransaksiProduksi' && $route.params.tipe==='2'}]">
              <router-link to="/trproduksi/2">Non Direct Material</router-link>
            </li>
            <li :class="[{'active':$route.name==='TransaksiProduksi' && $route.params.tipe==='3'}]">
              <router-link to="/trproduksi/3">Non Direct Labor</router-link>
            </li>
            <li :class="[{'active':$route.name==='TransaksiProduksi' && $route.params.tipe==='4'}]">
              <router-link to="/trproduksi/4">Overhead Lainnya</router-link>
            </li>
            <!-- <li>
              <router-link to="/">Hitung HPP</router-link>
            </li> -->

          </ul>
        </ul>

        <div class="menu-head">
          <span>Laporan</span>
        </div>
        <ul class="menu-app">
          <li class="dropdown-btn">
            <a href="#mnuLapKeu" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-scroll"></span>
              Keuangan
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="mnuLapKeu" class="collapse">
            <li :class="[{'active':$route.name==='MutasiKas'}]" v-if="getPengguna.levelx=='0'">
              <router-link to="/mutasikas">Mutasi Kas</router-link>
            </li>
            <li :class="[{'active':$route.name==='MutasiBank'}]" v-if="getPengguna.levelx=='0'">
              <router-link to="/mutasibank">Mutasi Bank</router-link>
            </li>
            <li :class="[{'active':$route.name==='BukuBesar'}]">
              <router-link to="/bukubesar">Buku Besar</router-link>
            </li>
            <li :class="[{'active':$route.name==='LabaRugi'}]">
              <router-link to="/labarugi">Laba Rugi</router-link>
            </li>
          </ul>
          <li :class="[{'active':$route.name==='Youtube'}]">
            <router-link to="/">
              <span class="las la-tools"></span>
              Pengaturan
            </router-link>
          </li>

        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
  name: "Sidebar",
  data(){
    return{
      mainurl_file: axios.defaults.baseURL,
    }
  },
  methods: {
    logOut() {
      localStorage.removeItem("token_app");
      this.$store.dispatch("acPengguna", {}); //membersihkan data pengguna di state
      this.$router.push("/login");
    },
    closeAll() {
      // $(".collapse").collapse({ toggle: false }).collapse("hide");
    },
  },
  computed: {
    ...mapGetters(["getPengguna"]),
  },
};
</script>

<style>
body.login {
  background-color: #ff6700;
}
</style>