<template>
  <!-- Breadcrumb-->
  <div class="">
    <ul class="breadcrumb small">
      <li class="breadcrumb-item">
        <router-link to="/trkas">Daftar Transaksi Kas</router-link>
      </li>
      <li class="breadcrumb-item active">Transaksi Kas </li>
    </ul>
  </div>
  <section class="no-padding-top">
    <div class="">
      <div class="row">
        <!-- Basic Form-->
        <div class="col-lg-7">
          <div class="card border-0">
            <div class="card-header border-0">
              <div class="title"><strong class="d-block">Form Transaksi</strong><span class="d-block">Isikan informasi
                  transaksi dengan benar.</span></div>
            </div>
            <div class="card-body border-0">
              <form>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-control-label">Jenis Transaksi</label>
                    <select class="form-control form-control-sm" v-model="jenis_trx">
                      <option value="masuk">Dana Masuk</option>
                      <option value="keluar">Dana Keluar</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="form-control-label">Tgl. Transaksi</label>
                    <input type="date" class="form-control form-control-sm" v-model="tgl">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-5">
                    <label class="form-control-label">No Faktur</label>
                    <input type="text" placeholder="Isikan jika ada" class="form-control form-control-sm"
                      v-model="nofaktur">
                  </div>

                  <div class="form-group col-sm-7">
                    <label class="form-control-label">Pos Akun Keuangan</label>
                    <div class="input-group">
                      <input type="text" placeholder="Pilih pos akun keuangan ..." class="form-control form-control-sm"
                        v-model="namaperk">
                      <div class="input-group-append">
                        <button type="button" class="btn btn-sm btn-primary" @click="modalNoperk()"><i
                            class="fa fa-search" aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-sm-12">
                    <label class="form-control-label">Uraian</label>
                    <input type="text" class="form-control form-control-sm"
                      placeholder="isikan deskripsi singkat transaksi ..." v-model="uraian">
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-sm-7">

                  </div>
                  <div class="form-group col-sm-5">
                    <label class="form-control-label">Nominal</label>
                    <input class="form-control form-control-sm" type="number" v-model="nominal">
                    <br>Rp {{ $filters.angka(nominal) }}
                  </div>
                </div>

              </form>
            </div>
            <div class="card-footer border-0">
              <div class="pull-right">
                <input type="submit" @click.prevent="simpan" value="Simpan" class="btn btn-primary">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- Modal-->
  <div id="modalNoperk" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    class="modal fade text-left">
    <div role="document" class="modal-dialog modal-dialog-scrollable  modal-lg">
      <div class="modal-content">
        <div class="modal-header"><strong id="exampleModalLabel" class="modal-title">Cari Pos Akun</strong>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close"><span
              aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <NoperkList @dataChild="dataChild" ref="ref_noperk" />
        </div>
        <div class="modal-footer">
          <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">Batal</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import NoperkList from "../components/NoperkList.vue";
export default {
  name: "AddKas",
  data() {
    return {
      jenis_trx: "keluar",
      tgl: new Date().toISOString().slice(0, 10),
      nofaktur: "",
      nominal: "",
      uraian: "",
      masuk: 0,
      keluar: 0,
      noperk: "",
      namaperk: "",
      golakun: "6",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    simpan() {
      //validasi input
      if (!this.namaperk || !this.noperk) {
        swal("Gagal", "Pilih pos akun keuangan terlebih dahulu!", "error");
        return;
      }
      if (this.uraian == "") {
        swal("Gagal", "isikan uraian terlebih dahulu!", "error");
        return;
      }
      let datax;
      if (this.jenis_trx == "masuk") {
        datax = {
          tgl: this.tgl,
          nofaktur: this.nofaktur,
          masuk: this.nominal,
          keluar: 0,
          noperk: this.noperk,
          uraian: this.uraian,
          idk: this.$route.params.idk,
        };
      } else {
        datax = {
          tgl: this.tgl,
          nofaktur: this.nofaktur,
          masuk: 0,
          keluar: this.nominal,
          noperk: this.noperk,
          uraian: this.uraian,
          idk: this.$route.params.idk,
        };
      }

      //jika simpan baru
      axios
        .post("api/kas", datax)
        .then((res) => {
          swal("Berhasil", "Data tersimpan", "success");
          this.$router.push("/trkas?idk=" + this.$route.params.idk);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modalNoperk() {
      if (this.jenis_trx == "keluar") {
        this.golakun = "6";
      } else {
        this.golakun = "4";
      }
      this.$refs.ref_noperk.getData(this.golakun);
      $("#modalNoperk").modal("show");
    },
    dataChild(x) {
      this.noperk = x.idx;
      this.namaperk = x.nama;
      $("#modalNoperk").modal("hide");
    },
  },
  components: {
    NoperkList,
  },
};
</script>

<style>
.kolep {
  cursor: pointer;
}

.kolep:hover {
  color: yellowgreen;
}
</style>