<template>
  <div class="row mb-3">
    <div class="col-sm-6">
      <label>Golongan Akun</label>
      <select class="form-control form-control-sm" v-model="golakun" @change="ubahGol">
        <option v-for="(x, index) in list_golakun" :key="index" :value="x.no">{{x.gol}}</option>
      </select>
    </div>
    <div class="col-sm-6">
      <label>Pencarian</label>
      <input type="text" class="form-control form-control-sm" placeholder="Pencarian ..." aria-label="Pencarian ..." aria-describedby="pencarian" v-model="cari" @keyup="pencarian">
    </div>
  </div>
  <div class="container">
    <div class="spinner-grow" role="status" v-show="loading">
      <span class="visually-hidden"></span>
    </div>
    <table class="table table-hover table-sm" v-show="!loading">
      <thead class="thead">
        <tr>
          <th>No Akun</th>
          <th>Nama Akun</th>
          <th>Golongan</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(x, index) in filterdatae" :key="index">
          <td>{{x.noperk}}</td>
          <td>{{x.namaperk}}</td>
          <td>
            <span v-if="x.kelompok=='1'">Aset</span>
            <span v-if="x.kelompok=='2'">Kewajiban</span>
            <span v-if="x.kelompok=='3'">Modal</span>
            <span v-if="x.kelompok=='4'">Pendapatan</span>
            <span v-if="x.kelompok=='5'">HPP</span>
            <span v-if="x.kelompok=='6'">Biaya</span>
          </td>
          <td>
            <div class="btn-group">
              <button type="button" @click="pilih(x.noperk, x.namaperk)" class="btn btn-success btn-sm pull-right">
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import axios from "axios";
import { $array } from "alga-js";

export default {
  name: "NoperkList",
  data() {
    return {
      datae: {},
      cari: "",
      filterdatae: {},
      list_golakun: [
        { no: "1", gol: "Aset" },
        { no: "2", gol: "Kewajiban" },
        { no: "3", gol: "Modal" },
        { no: "4", gol: "Pendapatan" },
        { no: "5", gol: "HPP" },
        { no: "6", gol: "Biaya" },
      ],
      golakun:'6',
      loading: false,
    };
  },
  mounted(){
    this.getData(this.golakun);
  },

  methods: {
    getData(x) {
      this.loading = true;
      axios
        .get("api/noperk/gol/" + x)
        .then((response) => {
          this.datae = response.data;
          this.filterdatae = $array.search(this.cari)(this.datae);
          this.loading = false;
          this.golakun=x;
          // console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ubahGol(){
      this.getData(this.golakun);
    },
    pilih(noperk, namaperk) {
      this.$emit("dataChild", { nama: namaperk, idx: noperk });
    },
    pencarian() {
      this.filterdatae = $array.search(this.cari)(this.datae);
    },
  }
};
</script>

<style>
</style>