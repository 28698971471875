<template>
  <ol class="breadcrumb rounded-0 bg-white">
    <router-link class="breadcrumb-item" to="/laporan">
      <b>Laporan Keuangan</b>
    </router-link>
    <li class="breadcrumb-item active" aria-current="page">
      Laba Rugi
    </li>
  </ol>

  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3 ">
            <div class="title d-flex justify-content-between">
              <div class=" d-none d-sm-block"><strong>Laba Rugi</strong></div>
              <div class="d-none d-sm-none">
                <div class="input-group">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit"><i class="fa fa-refresh"
                        aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-sm btn-warning" @click="cetak"><i class="fa fa-print"
                        aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="title d-flex justify-content-between">
              <div class="form-inline d-none d-sm-block">
                Mulai&nbsp;
                <input type="date" class="form-control form-control-sm" v-model="tglawal">
                &nbsp;s/d&nbsp;
                <input type="date" class="form-control form-control-sm" v-model="tglakhir">
                <button type="button" class="btn btn-sm btn-success" @click="getUnit"><i class="fa fa-refresh"
                    aria-hidden="true"></i> Tampil</button>
              </div>
            </div>

            <div class="d-flex justify-content-between d-sm-none" style="cursor: pointer">
              <p class="col-sm-12 mr-2 ml-2 mt-2" data-bs-toggle="collapse" data-bs-target="#tgl">
                Pilih Tanggal
              </p>
              <i class="fa fa-sort-down pull-right mr-2" aria-hidden="true"></i>
            </div>
            <div class="col-sm-12 row d-sm-none card">
              <div id="tgl" class="collapse  card-body" data-parent="#tgl">
                <div class="col-sm-6">
                  <label class="form-control-label small">Tgl. Mulai</label>
                  <input type="date" class="form-control form-control-sm" v-model="tglawal" />
                </div>
                <div class="col-sm-6">
                  <label class="form-control-label small">s/d</label>
                  <div class="input-group">

                    <input type="date" class="form-control form-control-sm" v-model="tglakhir" />
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>



          </div>
          <div class="card-body">
<div class="row">
  <div class="col-sm-6 mb-3">
                <div class="form-inline">
                  <label class="mr-2">Kegiatan Usaha</label>
                  <select class="form-control form-control-sm" v-model="idk" @change="getUnit">
                    <option v-for="(x, index) in datakegiatane" :key="index" :value="x.idk">{{ x.kegiatan }}</option>
                  </select>
                </div>
              </div>
</div>

            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>
            <div class="table-responsive" id="cetak" v-show="!loading && !kosong">
              <table class="table table-striped table-hover small">
                <tbody>
                  <tr>
                    <th colspan="3" class="text-success">PENDAPATAN</th>
                    <th colspan="1" class="pull-right text-success">Rp {{ $filters.angka(totpendapatan) }}</th>
                  </tr>
                  <tr v-for="(x, index) in datae.pendapatan" :key="index">
                    <td><small>{{ x.noperk }} </small></td>
                    <td>{{ x.namaperk }}</td>
                    <td align="right">{{ $filters.angka(x.debit) }}</td>
                    <td align="right">{{ $filters.angka(x.kredit) }}</td>
                  </tr>
                  <!-- <tr>
                    <th colspan="2" class="text-center">Total</th>
                    <td align="right"></td>
                    <td align="right">Rp {{$filters.angka(totpendapatan)}}</td>
                  </tr> -->

                  <tr>
                    <th colspan="3" class="text-info">HPP</th>
                    <th colspan="1" class="pull-right text-info">Rp {{ $filters.angka(tothpp) }}</th>
                  </tr>
                  <tr v-for="(x, index) in datae.hpp" :key="index">
                    <td><small>{{ x.noperk }} </small></td>
                    <td>{{ x.namaperk }}</td>
                    <td align="right">{{ $filters.angka(x.debit) }}</td>
                    <td align="right">{{ $filters.angka(x.kredit) }}</td>
                  </tr>
                  <!-- <tr>
                    <th colspan="2" class="text-center">Total</th>
                    <td align="right">Rp {{$filters.angka(tothpp)}}</td>
                    <td align="right"></td>
                  </tr> -->

                  <tr>
                    <th colspan="3" class="text-info">BEBAN</th>
                    <th colspan="1" class="pull-right text-info">Rp {{ $filters.angka(totbeban) }}</th>
                  </tr>
                  <tr v-for="(x, index) in datae.beban" :key="index">
                    <td><small>{{ x.noperk }} </small></td>
                    <td>{{ x.namaperk }}</td>
                    <td align="right">{{ $filters.angka(x.debit) }}</td>
                    <td align="right">{{ $filters.angka(x.kredit) }}</td>
                  </tr>
                  <!-- <tr>
                    <th colspan="2" class="text-center">Total</th>
                    <td align="right">Rp {{$filters.angka(totbeban)}}</td>
                    <td align="right"></td>
                  </tr> -->

                  <tr>
                    <th></th>
                    <th colspan="2" class="text-black">TOTAL
                      <span v-if="totpendapatan - (totbeban + tothpp) >= 0">LABA</span>
                      <span v-if="totpendapatan - (totbeban + tothpp) < 0">RUGI</span>
                    </th>
                    <td align="right" class="text-black"><strong>
                        <h4>Rp {{ $filters.angka(totpendapatan - (totbeban + tothpp)) }}</h4>
                      </strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p align="center" v-show="kosong">Belum ada data.</p>


          </div>
        </div>
      </div>
    </div>
  </section>








</template>

<script>
import axios from "axios";
export default {
  name: "LabaRugi",
  data() {
    return {
      datae: {
        pendapatan: [],
        hpp: [],
        beban: [],
      },
      id: "",
      tglawal: new Date().toISOString().slice(0, 10),
      tglakhir: new Date().toISOString().slice(0, 10),
      kosong: false,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time,
      cari: "",

      idk: "",
      datakegiatane: {}
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.getKegiatan();
  },
  methods: {
    getKegiatan() {
      this.loading = true;
      axios
        .get("api/kegiatan")
        .then((res) => {
          this.datakegiatane = res.data;
          if (res.data.length > 0) {
            this.idk = res.data[0].idk;
            if (this.$route.query.idk) {
              this.idk = this.$route.query.idk;
              // this.getUnit();
            } else {
              // this.getUnit();
            }
          } else {
            swal("Perhatian!", "Belum ada data kegiatan usaha! Tambahkan terlebih dahulu di menu Basis Data - Kegiatan", "Warning")
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnit() {
      this.loading = true;
      axios
        .get("api/laporan/labarugi/" + this.tglawal + "/" + this.tglakhir+"?idk="+this.idk)
        .then((res) => {
          this.datae.pendapatan = res.data.pendapatan;
          this.datae.hpp = res.data.hpp;
          this.datae.beban = res.data.beban;
          // if (res.data.pendapatan.length > 0 || res.data.hpp.length > 0 || res.data.beban.length > 0) {
          //   this.kosong = false;
          // } else {
          //   this.kosong = true;
          // }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async cetak() {
      // const style =
      //   "@page { margin-top: 110px } @media print { body { font-family: 'Poppins', sans-serif; color:blue; }}";
      const style = "@page { margin-top: 50px } body { font-family: 'Poppins', sans-serif; color:black;} table{border-collapse:collapse;border:1px solid black;width:100%;} table, td, th{border:1px solid black;} th, td{padding:5px;}}"
      const headerStyle = "font-weight: 300; font-size:1.2em";
      await printJS({
        printable: "cetak",
        type: "html",
        header: "Laporan Keuangan per " + this.$filters.tglIndo(this.tglawal) + " s/d " + this.$filters.tglIndo(this.tglakhir),
        headerStyle: headerStyle,
        style: style,
        scanStyles: false,
        onPrintDialogClose: () =>
          console.log("Halaman cetak ditutup"),
        onError: e => console.log(e)
      });
    }
  },
  computed: {
    totpendapatan() {
      let sum = 0;
      for (let i = 0; i < this.datae.pendapatan.length; i++) {
        sum += (parseFloat(this.datae.pendapatan[i].kredit) - parseFloat(this.datae.pendapatan[i].debit));
      }
      return sum;
    },
    tothpp() {
      let sum = 0;
      for (let i = 0; i < this.datae.hpp.length; i++) {
        sum += (parseFloat(this.datae.hpp[i].debit) - parseFloat(this.datae.hpp[i].kredit));
      }
      return sum;
    },
    totbeban() {
      let sum = 0;
      for (let i = 0; i < this.datae.beban.length; i++) {
        sum += (parseFloat(this.datae.beban[i].debit) - parseFloat(this.datae.beban[i].kredit));
      }
      return sum;
    },
  },
};
</script>

<style>

</style>