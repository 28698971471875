import { createRouter, createWebHistory } from "vue-router";
import { store } from "./store";

import Beranda from "./menu/Beranda.vue";
import Noperk from "./menu/Noperk.vue";
import Kegiatan from "./menu/Kegiatan.vue";
import Mitra from "./menu/Mitra.vue";
import TransaksiKas from "./menu/TransaksiKas.vue";
import AddKas from "./menu/AddKas.vue";
import TransaksiBank from "./menu/TransaksiBank.vue";
import AddBank from "./menu/AddBank.vue";
import Bank from "./menu/Bank.vue";
import TransaksiProduksi from "./menu/TransaksiProduksi.vue";
import AddProduksi from "./menu/AddProduksi.vue";

import Login from "./layouts/Login.vue";
import Dashboard from "./layouts/Dashboard.vue";

import MutasiKas from "./laporan/MutasiKas.vue";
import MutasiBank from "./laporan/MutasiBank.vue";
import BukuBesar from "./laporan/BukuBesar.vue";
import LabaRugi from "./laporan/LabaRugi.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "Beranda",
        component: Beranda,
      },
      {
        path: "mitra",
        name: "Mitra",
        component: Mitra,
      },
      {
        path: "trkas",
        name: "TransaksiKas",
        component: TransaksiKas,
      },
      {
        path: "trkas/:idk/baru",
        name: "AddKas",
        component: AddKas,
      },
      {
        path: "trbank",
        name: "TransaksiBank",
        component: TransaksiBank,
      },
      {
        path: "trbank/:idk/baru",
        name: "AddBank",
        component: AddBank,
      },
      {
        path: "trproduksi/:tipe",
        name: "TransaksiProduksi",
        component: TransaksiProduksi,
      },
      {
        path: "trproduksi/:tipe/:idk/baru",
        name: "AddProduksi",
        component: AddProduksi,
      },
      {
        path: "noperk",
        name: "Noperk",
        component: Noperk,
        meta: { admYayasan: true },
      },
      {
        path: "bank",
        name: "Bank",
        component: Bank,
        meta: { admYayasan: true },
      },
      {
        path: "kegiatan",
        name: "Kegiatan",
        component: Kegiatan,
        meta: { admYayasan: true },
      },

      {
        path: "mutasikas",
        name: "MutasiKas",
        component: MutasiKas,
      },
      {
        path: "mutasibank",
        name: "MutasiBank",
        component: MutasiBank,
      },
      {
        path: "bukubesar",
        name: "BukuBesar",
        component: BukuBesar,
      },
      {
        path: "labarugi",
        name: "LabaRugi",
        component: LabaRugi,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//untuk ngetrack arah router
router.beforeEach((to, from, next) => {
  // console.log(from, to); //to, from untuk liat path, next
  // next(); //->untuk memanggil halaman yg dituju

  var udahLogin = false;
  if (localStorage.getItem("token_app") === null) {
    udahLogin = false;
  } else {
    udahLogin = true;
  }

  //cek apakah tujuan selain lokasi login dan kondisi belum login
  if (to.name !== "Login" && !udahLogin) {
    next({ name: "Login" }); //=> maka dilempar kembali ke halaman login
  } else if (to.name === "Login" && udahLogin) {
    //jika posisi udah login gag boleh menuju halaman login lagi
    next({ name: "Beranda" });
  } else {
    if (to.meta.admStaff && localStorage.getItem("levelx") != "0") {
      next({ name: "Beranda" });
      return;
    }
    if (to.meta.admAdmin && localStorage.getItem("levelx") != "1") {
      next({ name: "Beranda" });
      return;
    }

    next(); //-> tapi klo udah login ke beranda baru bisa keaman aja
  }
});

export default router;
