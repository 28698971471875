<template>
  <ol class="breadcrumb rounded-0 bg-white small">
    <li class="breadcrumb-item">
      <b>Basis Data</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Kegiatan Usaha</li>
  </ol>
  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3 d-flex justify-content-between">
            <div><strong>Daftar Kegiatan Usaha</strong></div>
            <div>
              <button
                type="button"
                class="btn btn-sm btn-primary d-md-none d-sm-block"
                @click="addModal"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-primary d-none d-sm-none d-md-block"
                @click="addModal"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Tambah
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>
            <div class="row" v-show="!loading && !kosong">
              <div class="card mb-1">
                <div
                  class="card-body px-0 py-1"
                  v-for="(x, index) in datae"
                  :key="index"
                >
                  <div class="d-flex justify-content-between">
                    <div class="col">
                      {{ x.kegiatan }}
                      <br />
                      <small>{{ $filters.tglIndo(x.tglreg) }}</small>
                    </div>

                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-default btn-sm pull-right text-info"
                        @click="getUnitDetail(x.idk, index)"
                      >
                        <i class="fa fa-pen bigfonts" aria-hidden="true"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default btn-sm text-danger"
                        @click="deleteUnit(x.idk, index)"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p align="center" v-show="kosong">
              Belum ada data. Klik tombol tambah untuk menambahkan
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal-->
  <div
    id="modalTambah"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    class="modal fade text-left"
  >
    <div role="document" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <strong id="exampleModalLabel" class="modal-title">Kegiatan Usaha</strong>
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group col-sm-6">
              <label>Nama Kegiatan Usaha</label>
              <input
                type="text"
                placeholder="Kegiatan Usaha"
                class="form-control form-control-sm"
                v-model="kegiatan"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-secondary"
          >
            Batal
          </button>
          <button type="button" class="btn btn-primary" @click.prevent="simpan">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Kegiatan",
  data() {
    return {
      datae: {},
      idk: "",
      kegiatan: "",
      kosong: false,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time
      dataasete: {},
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getUnit();
  },
  methods: {
    getUnit() {
      this.loading = true;
      axios
        .get("api/kegiatan")
        .then((res) => {
          this.datae = res.data;
          if (res.data.length > 0) {
            this.kosong = false;
          } else {
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUnitDetail(x, index) {
      axios
        .get("api/kegiatan/" + x)
        .then((res) => {
          // console.log(res.data);
          this.idk = res.data.idk;
          this.kegiatan = res.data.kegiatan;
          this.index = index;
          $("#modalTambah").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addModal() {
      this.kegiatan = "";
      this.idk = "";
      $("#modalTambah").modal("show");
    },
    addUnit() {
      const datax = {
        kegiatan: this.kegiatan,
      };
      axios
        .post("api/kegiatan", datax)
        .then((res) => {
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.getUnit();

          $("#modalTambah").modal("hide");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editUnit() {
      const datax = {
        idk: this.idk,
        kegiatan: this.kegiatan,
      };
      axios
        .put("api/kegiatan", datax)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.getUnit();
          // this.datae[this.index].bank = this.bank; //untuk mengedit array secara langsung
          // this.datae[this.index].norek = this.norek; //untuk mengedit array secara langsung
          // this.datae[this.index].an = this.an; //untuk mengedit array secara langsung
          // this.datae[this.index].namaperk = res.data.namaperk; //untuk mengedit array secara langsung
          $("#modalTambah").modal("hide");
          // swal("Berhasil", res.data.pesan, "success");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUnit(x, index) {
      swal({
        title: "Hapus Kegiatan Usaha ini?",
        text: "Sekali terhapus data tidak akan bisa dikembalikan!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete("api/kegiatan/" + x)
            .then((res) => {
              if (res.data.status == "gagal") {
                swal("Gagal", res.data.pesan, "error");
                return;
              }
              // this.datae.splice(index, 1); //untuk menghapus array secara langsung
              this.getUnit();

              // swal("Berhasil", "data telah dihapus!", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //		 swal("Your imaginary file is safe!");
        }
      });
    },
    simpan() {
      if (!this.kegiatan) {
        swal("Perhatian", "Lengkapi isian!", "warning");
        return;
      }
      if (this.idk) {
        this.editUnit();
      } else {
        this.addUnit();
      }
    },
  },
};
</script>

<style></style>
