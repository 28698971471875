<template>
  <!-- Breadcrumb-->
  <div class="">
    <ul class="breadcrumb small">
      <li class="breadcrumb-item">
        <router-link to="/trbank">Daftar {{tipebiayae}}</router-link>
      </li>
      <li class="breadcrumb-item active">Biaya {{tipebiayae}} </li>
    </ul>
  </div>
  <section class="no-padding-top">
    <div class="">
      <div class="row">
        <!-- Basic Form-->
        <div class="col-lg-8">
          <div class="card border-0">
            <div class="card-header border-0">
              <div class="title"><strong class="d-block">Form Transaksi</strong><span class="d-block">Isikan informasi
                  transaksi dengan benar.</span></div>
            </div>
            <div class="card-body border-0">
              <form>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-control-label">Tgl. Transaksi</label>
                    <input type="date" class="form-control form-control-sm" v-model="tgl">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-5">
                    <label class="form-control-label">No Faktur</label>
                    <input type="text" placeholder="Isikan jika ada" class="form-control form-control-sm"
                      v-model="nofaktur">
                  </div>

                </div>

                <div class="row">
                  <div class="form-group col-sm-12">
                    <label class="form-control-label">Uraian</label>
                    <input type="text" class="form-control form-control-sm"
                      placeholder="isikan deskripsi singkat transaksi ..." v-model="uraian">
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-sm-7">
                    <label class="form-control-label">Cara Bayar</label>
                    <select class="form-control form-control-sm" v-model="idb">
                      <option value="0">Tunai (Kas)</option>
                      <option v-for="(x, index) in databanke" :key="index" :value="x.idb">{{ x.bank }}</option>
                      <option value="999">Hutang</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-5">
                    <label class="form-control-label">Nominal</label>
                    <input class="form-control form-control-sm" type="number" v-model="nominal">
                    <br>Rp {{ $filters.angka(nominal) }}
                  </div>
                </div>

              </form>
            </div>
            <div class="card-footer border-0">
              <div class="pull-right">
                <input type="submit" @click.prevent="simpan" value="Simpan" class="btn btn-primary">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- Modal-->
  <div id="modalNoperk" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    class="modal fade text-left">
    <div role="document" class="modal-dialog modal-dialog-scrollable  modal-lg">
      <div class="modal-content">
        <div class="modal-header"><strong id="exampleModalLabel" class="modal-title">Cari Pos Akun</strong>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close"><span
              aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <NoperkList @dataChild="dataChild" ref="ref_noperk" />
        </div>
        <div class="modal-footer">
          <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">Batal</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import NoperkList from "../components/NoperkList.vue";
import { store } from "../store";
export default {
  name: "AddBank",
  data() {
    return {
      tgl: new Date().toISOString().slice(0, 10),
      nofaktur: "",
      nominal: "",
      uraian: "",
      masuk: 0,
      keluar: 0,
      noperk: "",
      namaperk: "",
      golakun: "6",
      idb: "",
      databanke: {},

      tipe:this.$route.params.tipe,

    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.getBank();
  },
  methods: {
    getBank() {
      this.loading = true;
      axios
        .get("api/masterbank")
        .then((res) => {
          this.databanke = res.data;
          if (res.data.length > 0) {
            this.idb = res.data[0].idb;
            this.bankkosong = false;
          } else {
            this.bankkosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    simpan() {
      //validasi input
      if (this.idb == "" || !this.idb) {
        swal("Perhatian", "Anda belum memiliki metode bayar!", "warning");
        return;
      }
      if (this.uraian == "") {
        swal("Gagal", "isikan uraian terlebih dahulu!", "error");
        return;
      }
      if (this.nominal <1) {
        swal("Gagal", "isikan nominal terlebih dahulu!", "error");
        return;
      }
      let datax;
      
        datax = {
          tipetr: this.idb,
          tgl: this.tgl,
          nofaktur: this.nofaktur,
          nominal: this.nominal,
          tipe: this.$route.params.tipe,
          uraian: this.uraian,
          idk: this.$route.params.idk,
        };

      //jika simpan baru
      axios
        .post("api/produksi", datax)
        .then((res) => {
          swal("Berhasil", "Data tersimpan", "success");
          this.$router.push("/trproduksi/"+this.tipe+"?idk=" + this.$route.params.idk);
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
 
  computed: {
   
    tipebiayae(){
      if(this.$route.params.tipe=='0'){
        return 'Bahan Baku';
      }
      if(this.$route.params.tipe=='1'){
        return 'Tenaga Kerja Langsung';
      }
      if(this.$route.params.tipe=='2'){
        return 'Bahan Material Tidak Langsung';
      }
      if(this.$route.params.tipe=='3'){
        return 'Tenaga Kerja Tidak Langsung';
      }
      if(this.$route.params.tipe=='4'){
        return 'Overhead Lainnya';
      }
    }
   
  },
};
</script>

<style>
.kolep {
  cursor: pointer;
}

.kolep:hover {
  color: yellowgreen;
}
</style>