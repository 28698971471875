<template>
  <ol class="breadcrumb  rounded-0 bg-white">
    <li class="breadcrumb-item">
      <b>Laporan Keuangan</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Buku Besar</li>
  </ol>

  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3 ">
            <!-- <div class="card-header py-3 d-flex  justify-content-between"> -->
            <div>
              <h5>Buku Besar</h5>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-6 mb-3 form-inline">
                <label class="mr-2">Kegiatan Usaha</label>
                <select class="form-control form-control-sm" v-model="idk" @change="getUnit">
                  <option v-for="(x, index) in datakegiatane" :key="index" :value="x.idk">{{ x.kegiatan }}</option>
                </select>
              </div>
              <div class="col-sm-6 mb-3 form-inline">
                <label class="mr-2">Pos Akun</label>
                <div class="input-group-append">
                  <input type="text" placeholder="Pilih pos akun keuangan ..." class="form-control form-control-sm"
                    v-model="namaperk" disabled>
                  <button type="button" class="btn btn-sm btn-primary" @click="modalNoperk()"><i class="fa fa-search"
                      aria-hidden="true"></i></button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-inline d-none d-sm-block">
                  <!-- Pos Akun &nbsp;
                <input type="text" placeholder="Pilih pos akun keuangan ..." class="form-control form-control-sm"
                  v-model="namaperk" disabled>
                <button type="button" class="btn btn-sm btn-primary" @click="modalNoperk()"><i class="fa fa-search"
                    aria-hidden="true"></i></button> -->
                  &nbsp;Mulai&nbsp;
                  <input type="date" class="form-control form-control-sm" v-model="tglawal">
                  &nbsp;s/d&nbsp;
                  <input type="date" class="form-control form-control-sm" v-model="tglakhir">
                  <button type="button" class="btn btn-sm btn-success" @click="getUnit"><i class="fa fa-refresh"
                      aria-hidden="true"></i> Tampilkan</button>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="input-group">
                <input type="text" class="form-control form-control-sm mb-3" placeholder="Pencarian ..."
                  aria-label="Pencarian ..." aria-describedby="pencarian" v-model="cari" @keyup="pencarian">
                <div class="input-group-append">
                  <button type="button" class="btn btn-sm btn-success" @click="getUnit"><i class="fa fa-refresh"
                      aria-hidden="true"></i></button>
                  <button type="button" class="btn btn-sm btn-warning d-none d-sm-block" @click="cetak"><i class="fa fa-print"
                      aria-hidden="true"></i></button>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between  d-sm-none" style="cursor:pointer;">
              <p class="col-sm-12" data-bs-toggle="collapse" data-bs-target="#tgl">Pilih Tanggal <i
                  class="fa fa-sort-down pull-right" aria-hidden="true"></i></p>
            </div>
            <div class="col-sm-12 row d-sm-none card mb-3">
              <div id="tgl" class="collapse  card-body" data-bs-parent="#tgl">
                <div class="col-sm-6">
                  <label class="form-control-label small">Tgl. Mulai</label>
                  <input type="date" class="form-control form-control-sm" v-model="tglawal" />
                </div>
                <div class="col-sm-6">
                  <label class="form-control-label small">s/d</label>
                  <div class="input-group">
                    <input type="date" class="form-control form-control-sm" v-model="tglakhir" />
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>
            <div class="table-responsive" id="cetak" v-show="!loading && !kosong">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tanggal</th>
                    <th>Uraian</th>
                    <th>Kredit</th>
                    <th>Debit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, index) in datafiltere" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td><small>{{ $filters.tglIndo(x.tglx) }} </small></td>
                    <td>
                      <small>{{ x.uraian }}</small>
                    </td>
                    <td align="right">{{ $filters.angka(x.kredit) }}</td>
                    <td align="right">{{ $filters.angka(x.debit) }}</td>
                    <td>
                      <div class="btn-group">
                        <button type="button" class="btn btn-info btn-sm"
                          @click="lihat($filters.tglIndo(x.tglx), x.waktu, x.namalengkap, x.NoKwit, x.kategori)"
                          data-toggle="tooltip" data-placement="right" title="Lihat operator">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="3" class="text-center">Total</th>
                    <td align="right">Rp {{ $filters.angka(totkredit) }}</td>
                    <td align="right">Rp {{ $filters.angka(totdebit) }}</td>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
            <p align="center" v-show="kosong">Belum ada data. Pilih pos akun keuangan dan kilk tombol refresh.</p>
            <h5 v-show="!loading && !kosong">Total Saldo Mutasi : Rp {{ $filters.angka(totkredit - totdebit) }}</h5>

          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal-->
  <div id="modalNoperk" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    class="modal fade text-left">
    <div role="document" class="modal-dialog modal-dialog-scrollable  modal-lg">
      <div class="modal-content">
        <div class="modal-header"><strong id="exampleModalLabel" class="modal-title">Cari Pos Akun</strong>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close"><span
              aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <NoperkList @dataChild="dataChild" ref="ref_noperk" />
        </div>
        <div class="modal-footer">
          <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">Batal</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NoperkList from "../components/NoperkList.vue";

import { $array } from "alga-js";
export default {
  name: "BukuBesar",
  data() {
    return {
      datae: {},
      datafiltere: {},
      id: "",
      tglawal: new Date().toISOString().slice(0, 10),
      tglakhir: new Date().toISOString().slice(0, 10),
      kosong: true,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time,
      cari: "",
      noperk: "",
      namaperk: "",

      idk: "",
      datakegiatane: {}
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getKegiatan();

  },
  methods: {
    getKegiatan() {
      this.loading = true;
      axios
        .get("api/kegiatan")
        .then((res) => {
          this.datakegiatane = res.data;
          if (res.data.length > 0) {
            this.idk = res.data[0].idk;
            if (this.$route.query.idk) {
              this.idk = this.$route.query.idk;
              // this.getUnit();
            } else {
              // this.getUnit();
            }
          } else {
            swal("Perhatian!", "Belum ada data kegiatan usaha! Tambahkan terlebih dahulu di menu Basis Data - Kegiatan", "Warning")
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnit() {
      if (!this.noperk || !this.namaperk) {
        swal("Pilih pos akun keuangan terlebih dahulu!");
        return;
      }
      this.loading = true;
      axios
        .get(
          "api/laporan/bukubesar/" +
          this.tglawal +
          "/" +
          this.tglakhir +
          "?noperk=" +
          this.noperk 
          +
          "&idk="+this.idk
        )
        .then((res) => {
          this.datae = res.data;
          this.datafiltere = $array.search(this.cari)(this.datae);
          if (res.data.length > 0) {
            this.kosong = false;
          } else {
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pencarian() {
      this.datafiltere = $array.search(this.cari)(this.datae);
    },
    modalNoperk() {
      if (this.jenis_trx == "keluar") {
        this.golakun = "6";
      } else {
        this.golakun = "4";
      }
      this.$refs.ref_noperk.getData(this.golakun);
      $("#modalNoperk").modal("show");
    },
    dataChild(x) {
      this.noperk = x.idx;
      this.namaperk = x.nama;
      $("#modalNoperk").modal("hide");
    },
    lihat(tgl, waktu, operator, NoKwit, kategori) {
      swal(
        "No. Transaksi : " + NoKwit + "\n\n",
        "Jenis Transaksi : " +
        kategori +
        "\n\nditambahkan oleh " +
        operator +
        "\n" +
        "tanggal " +
        tgl +
        " pukul " +
        waktu,
        "info"
      );
    },
    async cetak() {
      // const style =
      //   "@page { margin-top: 110px } @media print { body { font-family: 'Poppins', sans-serif; color:blue; }}";
      const style = "@page { margin-top: 50px } body { font-family: 'Poppins', sans-serif; color:black;} table{border-collapse:collapse;border:1px solid black;} table, td, th{border:1px solid black;} th, td{padding:5px;}}"
      const headerStyle = "font-weight: 300;font-size:20px;";
      await printJS({
        printable: "cetak",
        type: "html",
        header: "Buku Besar Akun " + this.namaperk + " \nper " + this.$filters.tglIndo(this.tglawal) + " s/d " + this.$filters.tglIndo(this.tglakhir),
        headerStyle: headerStyle,
        style: style,
        scanStyles: false,
        onPrintDialogClose: () => console.log("Halaman cetak ditutup"),
        onError: e => console.log(e)
      });
    }
  },
  computed: {
    totkredit() {
      let sum = 0;
      for (let i = 0; i < this.datafiltere.length; i++) {
        sum += parseFloat(this.datafiltere[i].kredit);
      }
      return sum;
    },
    totdebit() {
      let sum = 0;
      for (let i = 0; i < this.datafiltere.length; i++) {
        sum += parseFloat(this.datafiltere[i].debit);
      }
      return sum;
    },
  },
  components: {
    NoperkList,
  },
};
</script>

<style>

</style>