<template>
  <ol class="breadcrumb rounded-0 bg-white small">
    <li class="breadcrumb-item">
      <b>Laporan Keuangan</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Mutasi Kas</li>
  </ol>

  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3">

            <div class="title d-flex justify-content-between">
              <div>
                <strong>Mutasi Bank</strong> 
                <span class="text-danger">{{ title_batal }}</span>
              </div>
            </div>

          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 mb-3">
                <div class="form-inline">
                  <label class="mr-2">Kegiatan Usaha</label>
                  <select class="form-control form-control-sm" v-model="idk" @change="getUnit">
                    <option v-for="(x, index) in datakegiatane" :key="index" :value="x.idk">{{ x.kegiatan }}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="form-inline d-flex justify-content-end">
                  <label class="mr-2">Bank</label>
                  <select class="form-control form-control-sm" v-model="idb" @change="getUnit">
                    <option v-for="(x, index) in databanke" :key="index" :value="x.idb">{{ x.bank }}</option>
                  </select>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-sm-6">
                <div class="title d-flex justify-content-between">
                  <div class="form-inline d-none d-sm-block">
                    Mulai&nbsp;
                    <input type="date" class="form-control form-control-sm" v-model="tglawal">
                    &nbsp;s/d&nbsp;
                    <input type="date" class="form-control form-control-sm" v-model="tglakhir">
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit"><i class="fa fa-refresh"
                        aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>


            <div class="d-flex justify-content-between d-sm-none" style="cursor: pointer">
              <p class="col-sm-12 mr-2 ml-2 mt-2" data-bs-toggle="collapse" data-bs-target="#tgl">
                Pilih Tanggal
              </p>
              <i class="fa fa-sort-down pull-right mr-2" aria-hidden="true"></i>
            </div>
            <div class="col-sm-12 row d-sm-none card">
              <div id="tgl" class="collapse  card-body" data-parent="#tgl">
                <div class="col-sm-6">
                  <label class="form-control-label small">Tgl. Mulai</label>
                  <input type="date" class="form-control form-control-sm" v-model="tglawal" />
                </div>
                <div class="col-sm-6">
                  <label class="form-control-label small">s/d</label>
                  <div class="input-group">

                    <input type="date" class="form-control form-control-sm" v-model="tglakhir" />
                    <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </div>


          <div class="spinner-grow" role="status" v-show="loading">
            <span class="visually-hidden"></span>
          </div>
          <div class="table-responsive" v-show="!loading && !kosong">
            <table class="table table-striped table-hover small">
              <thead>
                <tr class="text-info">
                  <th colspan="3">PENERIMAAN</th>
                </tr>
                <tr>
                  <th>No. Akun</th>
                  <th>Pos Akun Keuangan</th>
                  <th>Nominal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, index) in datapenerimaane" :key="index" :class="tampil_css">
                  <th scope="row">{{ x.noperk }}</th>
                  <td>{{ x.namaperk }}</td>
                  <td align="right">{{ $filters.angka(x.masuk) }}</td>
                </tr>
                <tr class="text-info">
                  <th colspan="2">Total Penerimaan</th>
                  <td align="right">Rp {{ $filters.angka(totpenerimaan) }}</td>
                </tr>
              </tbody>
              <thead>
                <tr class="text-warning">
                  <th colspan="3">PENGELUARAN</th>
                </tr>
                <tr>
                  <th>No. Akun</th>
                  <th>Pos Akun Keuangan</th>
                  <th>Nominal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, index) in datapengeluarane" :key="index" :class="tampil_css">
                  <th scope="row">{{ x.noperk }}</th>
                  <td>{{ x.namaperk }}</td>
                  <td align="right">{{ $filters.angka(x.keluar) }}</td>
                </tr>
                <tr class="text-warning">
                  <th colspan="2">Total Pengeluaran</th>
                  <td align="right">Rp {{ $filters.angka(totpengeluaran) }}</td>
                </tr>
                <tr class="text-success">
                  <th colspan="2">TOTAL ARUS BANK</th>
                  <td align="right"><strong>Rp
                      {{ $filters.angka(parseFloat(totpenerimaan) - parseFloat(totpengeluaran)) }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p align="center" v-show="kosong">Belum ada data.</p>

        </div>
      </div>
    </div>
  </section>

</template>

<script>
import axios from "axios";
export default {
  name: "MutasiBank",
  data() {
    return {
      datapenerimaane: {},
      datapengeluarane: {},
      databanke: {},
      idb: "",
      id: "",
      tglawal: new Date().toISOString().slice(0, 10),
      tglakhir: new Date().toISOString().slice(0, 10),
      kosong: false,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time,

      idk: "",
      datakegiatane: {}
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.getKegiatan();
    this.getBank();

  },
  methods: {
    getKegiatan() {
      this.loading = true;
      axios
        .get("api/kegiatan")
        .then((res) => {
          this.datakegiatane = res.data;
          if (res.data.length > 0) {
            this.idk = res.data[0].idk;
            if (this.$route.query.idk) {
              this.idk = this.$route.query.idk;
              this.getUnit();
            } else {
              this.getUnit();
            }
          } else {
            swal("Perhatian!", "Belum ada data kegiatan usaha! Tambahkan terlebih dahulu di menu Basis Data - Kegiatan", "Warning")
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBank() {
      this.loading = true;
      axios
        .get("api/masterbank")
        .then((res) => {
          this.databanke = res.data;
          if (res.data.length > 0) {
            this.idb = this.databanke[0].idb;
            this.getUnit();
          } else {
            $router.push("/laporan");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnit() {
      this.loading = true;
      axios
        .get("api/laporan/mutasibank/" + this.idb + "/" + this.tglawal + "/" + this.tglakhir +"?idk="+this.idk)
        .then((res) => {
          this.datapenerimaane = res.data.penerimaan;
          this.datapengeluarane = res.data.pengeluaran;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    totpenerimaan() {
      let sum = 0;
      for (let i = 0; i < this.datapenerimaane.length; i++) {
        sum += parseFloat(this.datapenerimaane[i].masuk);
      }
      return sum;
    },
    totpengeluaran() {
      let sum = 0;
      for (let i = 0; i < this.datapengeluarane.length; i++) {
        sum += parseFloat(this.datapengeluarane[i].keluar);
      }
      return sum;
    },
  },
};
</script>

<style>

</style>