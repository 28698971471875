<template>
  <ol class="breadcrumb rounded-0 bg-white small">
    <li class="breadcrumb-item">
      <b>Produksi</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{tipebiayae}}</li>
  </ol>
  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3">
            <div class="d-flex justify-content-between col-lg-12">
              <div class="d-none d-sm-block">
                <strong>Biaya {{tipebiayae}}</strong>
              </div>
              <div>
                <div class="form-inline d-none d-sm-block">
                  Mulai&nbsp;
                  <input type="date" class="form-control form-control-sm" v-model="tglawal" />
                  &nbsp;s/d&nbsp;
                  <input type="date" class="form-control form-control-sm" v-model="tglakhir" />
                  <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-end mt-3">
              <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="Pencarian ..."
                  aria-label="Pencarian ..." aria-describedby="pencarian" v-model="cari" @keyup="pencarian" />
                <div class="input-group-append">
                  <router-link class="btn btn-sm btn-primary d-none d-sm-none d-md-block" :to="'/trproduksi/'+$route.params.tipe+'/'+idk+'/baru'"><i
                      class="fa fa-plus-circle" aria-hidden="true"></i>
                    Tambah</router-link>
                  <router-link class="btn btn-sm btn-primary d-md-none d-sm-block" :to="'/trproduksi/'+$route.params.tipe+'/'+idk+'/baru'"><i
                      class="fa fa-plus-circle" aria-hidden="true"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between d-sm-none" style="cursor: pointer">
            <p class="col-sm-12 mr-2 ml-2 mt-2" data-bs-toggle="collapse" data-bs-target="#tgl">
              Pilih Tanggal
            </p>
            <i class="fa fa-sort-down pull-right mr-2" aria-hidden="true"></i>
          </div>
          <div class="col-sm-12 row d-sm-none card">
            <div id="tgl" class="collapse  card-body" data-parent="#tgl">
              <div class="col-sm-6">
                <label class="form-control-label small">Tgl. Mulai</label>
                <input type="date" class="form-control form-control-sm" v-model="tglawal" />
              </div>
              <div class="col-sm-6">
                <label class="form-control-label small">s/d</label>
                <div class="input-group">

                  <input type="date" class="form-control form-control-sm" v-model="tglakhir" />
                  <button type="button" class="btn btn-sm btn-success" @click="getUnit">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm-6 mb-3 form-inline">
              <label class="mr-2">Kegiatan Usaha</label>
              <select class="form-control form-control-sm" v-model="idk" @change="getUnit">
                <option v-for="(x, index) in datakegiatane" :key="index" :value="x.idk">{{ x.kegiatan }}</option>
              </select>
            </div>

            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>

            <div class="table-responsive d-none d-sm-none d-md-block" v-show="!loading && !kosong">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tanggal</th>
                    <th>Uraian</th>
                    <th>Cara Bayar</th>
                    <th>Nominal</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, index) in datafiltere" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <small>{{ $filters.tglIndo(x.tglx) }} </small>
                    </td>
                    <td>{{ x.uraian }}</td>
                    <td>{{ x.carabayar }}</td>
                    <td>{{ $filters.angka(x.nominal) }}</td>
                    <td>
                      <div class="btn-group">
                        <button type="button" class="btn btn-info btn-sm pull-right"
                          @click="tampilInfo(x.tglentrix, x.waktuentrix, x.opr)">
                          <i class="fa fa-info bigfonts" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" @click="deleteUnit(x.nokwit, index)">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-center">Total</th>
                    <th>{{ $filters.angka(totmasuk) }}</th>
                    <th>{{ $filters.angka(totkeluar) }}</th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row d-md-none d-sm-block" v-show="!loading && !kosong">
              <div class="card mb-1">
                <div class="card-body px-0 py-1" v-for="(x, index) in datafiltere" :key="index">
                  <div class="d-flex justify-content-end">
                    <small class="text-info">{{ $filters.tglIndo(x.tglx) }} </small>
                  </div>
                  <div class="col-sm-12">
                    {{ x.uraian }} <br><small><span class="badge bg-info">{{ x.bank }}</span></small>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="btn-group">
                      <button type="button" class="btn btn-default btn-sm pull-right text-info"
                        @click="tampilInfo(x.tglentrix, x.waktuentrix, x.opr)">
                        <i class="fa fa-info bigfonts" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm text-danger"
                        @click="deleteUnit(x.nokwit, index)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>

                    <div class="mt-2 mr-2">
                      <div class="row text-danger">
                        Rp {{ $filters.angka(x.nominal) }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <span class="text-success small">Total</span>
                    <span class="text-success small">Rp {{ $filters.angka(totnominal) }}</span>
                  </div>
                
                </div>
              </div>


            </div>

            <p align="center" v-show="kosong">
              Belum ada data. Klik tombol tambah untuk menambahkan
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { $array } from "alga-js";
export default {
  name: "TransaksiProduksi",
  data() {
    return {
      datae: {},
      datafiltere: {},
      id: "",
      tglawal: new Date().toISOString().slice(0, 10),
      tglakhir: new Date().toISOString().slice(0, 10),
      kosong: false,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time,
      cari: "",

      idk:"",
      datakegiatane: {},
      tipe:this.$route.params.tipe,
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getKegiatan();
  },
  methods: {
    getKegiatan() {
      this.loading = true;
      axios
        .get("api/kegiatan")
        .then((res) => {
          this.datakegiatane = res.data;
          if (res.data.length > 0) {
            this.idk=res.data[0].idk;
            if(this.$route.query.idk){
              this.idk=this.$route.query.idk;
              this.getUnit();
            }else{
              this.getUnit();
            }
          } else {
            swal("Perhatian!", "Belum ada data kegiatan usaha! Tambahkan terlebih dahulu di menu Basis Data - Kegiatan", "Warning")
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnit() {
      this.loading = true;
      axios
        .get("api/produksi/"+this.$route.params.tipe+"/" + this.tglawal + "/" + this.tglakhir+"/"+this.idk)
        .then((res) => {
          this.datae = res.data;
          this.datafiltere = $array.search(this.cari)(this.datae);
          if (res.data.length > 0) {
            this.kosong = false;
          } else {
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    deleteUnit(x, index) {
      swal({
        title: "Hapus transaksi ini?",
        text: "Sekali terhapus data tidak akan bisa dikembalikan!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete("api/produksi/" + x)
            .then((res) => {
              if (res.data.status == "gagal") {
                swal("Gagal", res.data.pesan, "error");
                return;
              }
              this.datafiltere.splice(index, 1); //untuk menghapus array secara langsung

              // swal("Berhasil", "data telah dihapus!", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //		 swal("Your imaginary file is safe!");
        }
      });
    },
    pencarian() {
      this.datafiltere = $array.search(this.cari)(this.datae);
    },
    tampilInfo(tgl, waktu, operator) {
      swal(
        "Riwayat Data",
        "Ditambahkan oleh " +
        operator +
        "\n" +
        "tanggal " +
        this.$filters.tglIndo(tgl) +
        " - Pukul " +
        waktu,
        "info"
      );
    },
  },
  computed: {
    totnominal() {
      let sum = 0;
      for (let i = 0; i < this.datafiltere.length; i++) {
        sum += parseFloat(this.datafiltere[i].nominal);
      }
      return sum;
    },
    tipebiayae(){
      if(this.$route.params.tipe=='0'){
        return 'Bahan Baku';
      }
      if(this.$route.params.tipe=='1'){
        return 'Tenaga Kerja Langsung';
      }
      if(this.$route.params.tipe=='2'){
        return 'Bahan Material Tidak Langsung';
      }
      if(this.$route.params.tipe=='3'){
        return 'Tenaga Kerja Tidak Langsung';
      }
      if(this.$route.params.tipe=='4'){
        return 'Overhead Lainnya';
      }
    }
   
  },
};
</script>

<style>

</style>
