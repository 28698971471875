<template>
  <ol class="breadcrumb  rounded-0 bg-white small">
    <li class="breadcrumb-item">
      <b>Keuangan</b>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Bank</li>
  </ol>
  <section class="no-padding-top no-padding-bottom">
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card shadow mb-4 rounded-0">
          <div class="card-header py-3 d-flex  justify-content-between">
            <div><strong>Daftar Bank Terdaftar</strong></div>
            <div>
              <button type="button" class="btn btn-sm btn-primary d-md-none d-sm-block" @click="addModal"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
              <button type="button" class="btn btn-sm btn-primary d-none d-sm-none d-md-block" @click="addModal"><i class="fa fa-plus-circle" aria-hidden="true"></i> Tambah</button>
            </div>
          </div>
          <div class="card-body">

            <div class="spinner-grow" role="status" v-show="loading">
              <span class="visually-hidden"></span>
            </div>
            <div class="table-responsive" v-show="!loading&&!kosong">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Bank</th>
                    <th>Rekening</th>
                    <th>Korelasi Pos</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x,index) in datae" :key="index">
                    <th scope="row">{{index +1}}</th>
                    <td @click="getUnitDetail(x.idb, index)">{{x.bank}}</td>
                    <td @click="getUnitDetail(x.idb, index)"><small>{{x.norek}} a/n {{x.an}}</small></td>
                    <td @click="getUnitDetail(x.idb, index)">{{x.namaperk}}</td>
                    <td>
                      <div class="btn-group">
                        <router-link v-if="x.moota_aktif==1" :to="'/bank/'+x.bank_id" type="button" class="btn btn-primary btn-sm">
                          <i class="las la-exchange-alt"></i> Mutasi
                        </router-link>
                        <router-link v-if="x.moota_aktif==1" :to="'/bank/request/'+x.bank_id" type="button" class="btn btn-success btn-sm">
                          <i class="las la-clipboard-check"></i> Request
                        </router-link>
                        <button v-if="x.moota_aktif!=1" type="button" class="btn btn-success btn-sm" @click="getUnitDetail(x.idb, index)">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button v-if="x.moota_aktif!=1" type="button" class="btn btn-danger btn-sm" @click="deleteUnit(x.idb, index)">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p align="center" v-show="kosong">Belum ada data. Klik tombol tambah untuk menambahkan</p>

          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal-->
  <div id="modalTambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" class="modal fade text-left">
    <div role="document" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header"><strong id="exampleModalLabel" class="modal-title">Bank</strong>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group col-sm-6">
              <label>Nama Bank</label>
              <input type="text" placeholder="Nama Bank" class="form-control form-control-sm" v-model="bank">
            </div>
            <div class="form-group col-sm-12">
              <label>No Rekening</label>
              <input type="text" placeholder="No Rekening" class="form-control form-control-sm" v-model="norek">
            </div>
            <div class="form-group col-sm-12">
              <label>Atas Nama</label>
              <input type="text" placeholder="Atas nama pemilik rekening" class="form-control form-control-sm" v-model="an">
            </div>
            <div class="form-group col-sm-12">
              <label>Sambungan dengan Pos Akun Aset</label>
              <select class="form-control form-control-sm" v-model="noperk">
                <option v-for="(x, index) in dataasete" :key="index" :value="x.noperk">{{x.namaperk}}</option>
              </select>
              <br><small class="text-info">*Jika belum memiliki Akun Bank tambakan di POS Akun di Golongan Aset. </small>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">Batal</button>
          <button type="button" class="btn btn-primary" @click.prevent="simpan">Simpan</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
export default {
  name: "Kelas",
  data() {
    return {
      datae: {},
      bank: "",
      norek: "",
      an: "",
      idb: "",
      noperk: "",
      kosong: false,
      loading: false,
      index: "", //untuk digunakan update dan hapus secera real time
      dataasete: {},
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getUnit();
    this.getNoperkAset();
  },
  methods: {
    getUnit() {
      this.loading = true;
      axios
        .get("api/masterbank")
        .then((res) => {
          this.datae = res.data;
          if (res.data.length > 0) {
            this.kosong = false;
          } else {
            this.kosong = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNoperkAset() {
      this.loading = true;
      axios
        .get("api/noperk/golongan/aset")
        .then((res) => {
          this.dataasete = res.data;
          this.noperk = this.dataasete[0].noperk;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnitDetail(x, index) {
      axios
        .get("api/masterbank/" + x)
        .then((res) => {
          console.log(res.data);
          this.bank = res.data.bank;
          this.norek = res.data.norek;
          this.an = res.data.an;
          this.noperk = res.data.noperk;
          this.idb = res.data.idb;
          this.index = index;
          $("#modalTambah").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addModal() {
      this.bank = "";
      this.an = "";
      this.norek = "";
      // this.noperk = "";
      this.idb = "";
      $("#modalTambah").modal("show");
    },
    addUnit() {
      const datax = {
        bank: this.bank,
        norek: this.norek,
        an: this.an,
        noperk: this.noperk,
      };
      axios
        .post("api/masterbank", datax)
        .then((res) => {
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.getUnit();
          // this.datae.push({
          //   bank: this.bank,
          //   norek: this.norek,
          //   an: this.an,
          //   namaperk: res.data.namaperk,
          // });

          $("#modalTambah").modal("hide");
          // swal("Berhasil", res.data.pesan, "success");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editUnit() {
      const datax = {
        idb: this.idb,
        bank: this.bank,
        norek: this.norek,
        an: this.an,
        noperk: this.noperk,
      };
      axios
        .put("api/masterbank", datax)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "gagal") {
            swal("Gagal", res.data.pesan, "error");
            return;
          }
          this.getUnit();
          // this.datae[this.index].bank = this.bank; //untuk mengedit array secara langsung
          // this.datae[this.index].norek = this.norek; //untuk mengedit array secara langsung
          // this.datae[this.index].an = this.an; //untuk mengedit array secara langsung
          // this.datae[this.index].namaperk = res.data.namaperk; //untuk mengedit array secara langsung
          $("#modalTambah").modal("hide");
          // swal("Berhasil", res.data.pesan, "success");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUnit(x, index) {
      swal({
        title: "Hapus Bank ini?",
        text: "Sekali terhapus data tidak akan bisa dikembalikan!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete("api/masterbank/" + x)
            .then((res) => {
              if (res.data.status == "gagal") {
                swal("Gagal", res.data.pesan, "error");
                return;
              }
              // this.datae.splice(index, 1); //untuk menghapus array secara langsung
              this.getUnit();

              // swal("Berhasil", "data telah dihapus!", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //		 swal("Your imaginary file is safe!");
        }
      });
    },
    simpan() {
      if (
        !this.bank ||
        !this.an ||
        !this.norek ||
        !this.noperk ||
        this.noperk == ""
      ) {
        swal("Perhatian", "Lengkapi isian!", "warning");
        return;
      }
      if (this.idb) {
        this.editUnit();
      } else {
        this.addUnit();
      }
    },
  },
};
</script>

<style>
</style>