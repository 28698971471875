<template>
         <nav class="header">
            <div class="menu-toggle">
                <label for="sidebar-toggle">
                    <span class="las la-bars"></span>
                </label>
            </div>

        </nav>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name:'Navbar',
    computed:{
      ...mapGetters(['getPengguna'])
    },
}
</script>

<style>

</style>