import { createStore } from "vuex";

export const store = createStore({
    state(){
        return{
            penggunae:{}
        }
    },
    getters:{
        //agar bisa diambil dari state ini
        getPengguna(state){
            return state.penggunae;
        }
    },
    mutations:{
        //untuk masukkan data ke state
        setPengguna(state,datae){
            state.penggunae=datae;
        }
    },
    actions:{
        //fungsi utk memangiil data di component
        acPengguna(context, datae){
            context.commit('setPengguna', datae); //panggil mutations
        }
    },
})