import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import swal from 'sweetalert'
import './axios'
import { store } from './store'
import router from './router'
import './registerServiceWorker'

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')


//filter untuk format data dengan javascript
app.config.globalProperties.$filters = {
    angka(value) {
        return new Number(value).toLocaleString("id-ID");
    },
    fixedx(value) {
        return new Number(value).toFixed(0);
    },
    tglIndo(value) {
        var parts = value.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
        var bulan = tgle.getMonth();
        var tgl = tgle.getDate();
        var tahun = tgle.getFullYear();
        var hari = tgle.getDay();

        switch (hari) {
            case 0: hari = "Minggu"; break;
            case 1: hari = "Senin"; break;
            case 2: hari = "Selasa"; break;
            case 3: hari = "Rabu"; break;
            case 4: hari = "Kamis"; break;
            case 5: hari = "Jum'at"; break;
            case 6: hari = "Sabtu"; break;
        }
        switch (bulan) {
            case 0: bulan = "Januari"; break;
            case 1: bulan = "Februari"; break;
            case 2: bulan = "Maret"; break;
            case 3: bulan = "April"; break;
            case 4: bulan = "Mei"; break;
            case 5: bulan = "Juni"; break;
            case 6: bulan = "Juli"; break;
            case 7: bulan = "Agustus"; break;
            case 8: bulan = "September"; break;
            case 9: bulan = "Oktober"; break;
            case 10: bulan = "November"; break;
            case 11: bulan = "Desember"; break;
        }
        return hari + ", " + tgl + " " + bulan + " " + tahun;
    },
    hari(value) {
        var hari = value;
        switch (hari) {
            case '0': hari = "Minggu"; break;
            case '1': hari = "Senin"; break;
            case '2': hari = "Selasa"; break;
            case '3': hari = "Rabu"; break;
            case '4': hari = "Kamis"; break;
            case '5': hari = "Jum'at"; break;
            case '6': hari = "Sabtu"; break;
        }

        return hari;
    },
    hariInt(value) {
        var hari = value;
        switch (hari) {
            case 0: hari = "Ahad"; break;
            case 1: hari = "Senin"; break;
            case 2: hari = "Selasa"; break;
            case 3: hari = "Rabu"; break;
            case 4: hari = "Kamis"; break;
            case 5: hari = "Jum'at"; break;
            case 6: hari = "Sabtu"; break;
        }

        return hari;
    },
    bulanIndo(value) {
        var parts = value.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
        var bulan = tgle.getMonth();

        switch (bulan) {
            case 0: bulan = "Januari"; break;
            case 1: bulan = "Februari"; break;
            case 2: bulan = "Maret"; break;
            case 3: bulan = "April"; break;
            case 4: bulan = "Mei"; break;
            case 5: bulan = "Juni"; break;
            case 6: bulan = "Juli"; break;
            case 7: bulan = "Agustus"; break;
            case 8: bulan = "September"; break;
            case 9: bulan = "Oktober"; break;
            case 10: bulan = "November"; break;
            case 11: bulan = "Desember"; break;
        }
        return bulan;
    },
    bulanTahunIndo(value) {
        var parts = value.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
        var bulan = tgle.getMonth();
        var tahun = tgle.getFullYear();

        switch (bulan) {
            case 0: bulan = "Januari"; break;
            case 1: bulan = "Februari"; break;
            case 2: bulan = "Maret"; break;
            case 3: bulan = "April"; break;
            case 4: bulan = "Mei"; break;
            case 5: bulan = "Juni"; break;
            case 6: bulan = "Juli"; break;
            case 7: bulan = "Agustus"; break;
            case 8: bulan = "September"; break;
            case 9: bulan = "Oktober"; break;
            case 10: bulan = "November"; break;
            case 11: bulan = "Desember"; break;
        }
        return bulan + " " + tahun;
    },

    tahun(value) {
        var parts = value.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
        var tahun = tgle.getFullYear();

        return tahun;
    },

    basedocfile(value) {

        let valuex=value;
        let ekstensi;

        switch (valuex) {

            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                ekstensi = "docx";
                break;
            case "application/vnd.ms-excel":
                ekstensi = "xls";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                ekstensi = "xlsx";
                break;
            case "application/msword":
                ekstensi = "doc";
                break;
            case "application/vnd.ms-powerpoint":
                ekstensi = "ppt";
                break;
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                ekstensi = "pptx";
                break;
            default:
                ekstensi = "tidak ditemukan...";

        }
        return ekstensi;
    },
}
